.admin-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.admin-map {
  cursor: crosshair;
}

.map-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 500;
}